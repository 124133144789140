import React, { useState } from 'react';
import './Nav.css'

import Logo from './larus.png';

export const Nav = () => {
    return (
        <div>
            <div className='nav'>
            <div className='nav-copy'><img src={Logo} className='nav-logo'/>L A R U S</div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import global from './Global';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { useLocalStorage } from './Utils';

import { Nav } from './Nav.js'

import './Payment.css'

export const Payment = () => {
    const [userData, setData] = useLocalStorage('data', {
        companyName: '',
        email: '',
        about: '',
        why: '',
        vibe: '',
        subscribed: true,
    });

    const [promo, setPromo] = useLocalStorage('promotion', '')
    const [promoCode, setPromoCode] = useLocalStorage('promotionCode', '')
    const [promoValue, setPromoValue] = useLocalStorage('promotionValue', '100')

    let navigate = useNavigate();

    useEffect(() => {
        if (userData.email == "") {
            navigate('/brainstorming/');
        }
    }, []);

    const goToBrand = (id) => {
        navigate('/brand/' + id);
    };

    function createOrder() {
        console.log("promoCode")
        return fetch(global.endpoint + "api/payment", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "intent": "capture",
                "type": "create",
                "promoCode": promoCode
            })
        })
        .then((response) =>
            response.json()
        )
        .then((order) => { console.log(order); return order.id; });
    }

    function onApprove(data) {
        let order_id = data.orderID;
        return fetch(global.endpoint + "api/payment", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "intent": "capture",
                "order_id": order_id,
                "type": "complete",
                "companyName": userData.companyName,
                "email": userData.email,
                "subscribed": userData.subscribed,
                "about": userData.about,
                "why": userData.why,
                "vibe": userData.vibe
            })
        })
        .then((response) => response.json())
        .then((order_details) => {
            goToBrand(order_details.id)
        })
        .catch((error) => {

         });

    }

    function onCancel(data) {
    }

    function onError(err) {
        console.log(err)
    }

    return (
        <div>
            <Nav/> 
            <div className='payment-content'>
                <div className='cost-header'>${promoValue}</div>
                <div className='payment-header'>Branding Vision Payment</div>
                <div className='payment-body'>A fee of <b>${promoValue}</b> is required to get Larus preparing your unique, never before created Branding Vision. Choose the payment method that works best for you below.</div>
                <PayPalScriptProvider options={{ 
                    // "clientId": "Afc9wwoGAOJhG09bfrIflEl4OAhNHc_1VupGMVxR1rlP8tHuVaGW6jZX1F7AIlEC_17XfzOQJhHjc9a6",
                    "clientId": "AQ7smUofIwZQ2tdTc4otosF-8oVg-7NlJQru0vT1GdGE7LVWJyfWxxqFkhARxfgPZAG94CwumsuYpSyk",
                    "components": "buttons,funding-eligibility",
				    "enable-funding": "venmo",
                }}>
                <PayPalButtons
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onCancel={onCancel}
                    onError={onError}
                >
                </PayPalButtons>
                </PayPalScriptProvider>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { useLocalStorage, getStorageValue } from './Utils';

import global from './Global';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift } from '@fortawesome/free-solid-svg-icons'

import { Nav } from './Nav.js'
import { Footer } from './Footer.js'

import { useNavigate } from 'react-router-dom';

import './UserInput.css'

export const UserInput = () => {
    let navigate = useNavigate();
    const [data, setData] = useLocalStorage('data', {
        companyName: '',
        email: '',
        about: '',
        why: '',
        vibe: '',
        subscribed: true,
    });

    const [promo, setPromo] = useLocalStorage('promotion', '')
    const [promoCode, setPromoCode] = useLocalStorage('promotionCode', '')
    const [promoValue, setPromoValue] = useLocalStorage('promotionValue', '100')

    useEffect(() => {
        if (promo == "promo code is not valid or expired") {
            setPromo('');
        }
    }, []);

    const payment = () => {
      navigate('/payment/');
    };

    const handleSubmit = (event) => {
        event.preventDefault(event);
        payment()
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const regex = /[^a-zA-Z0-9.,!?;:'"()\-\s]/g;

        if (regex.test(value)) {
            // e.target.console.error();
        }

        var filteredValue = value;
        if (name != 'email') {
            filteredValue = value.replace(regex, '');
        }

        setData({
          ...data,
          [name]: filteredValue,
        });
    };

    const checkboxChange = (e) => {
        const { name, checked } = e.target;
        setData({
          ...data,
          [name]: checked,
        });
    };

    const handlePromoChange = (e) => {
        const { value } = e.target;
        setPromoCode(value)
    }

    const checkPromo = (e) => {
        if (promoCode != "" && promoCode.length > 4 && promoCode.length < 20) {
            let code = promoCode
            setPromoCode("")
            fetch(global.endpoint + "api/promo/" + code, {
                method: "get"
            })
            .then(response => response.json())
            .then(data => {
                if (data.promo && data.promoValue) {
                    setPromo(data.promo)
                    setPromoValue(data.promoValue)
                    setPromoCode(data.code)
                } else {
                    setPromo('promo code is not valid or expired')
                    setPromoValue("100")
                    setPromoCode('')
                    setTimeout(() => {
                        setPromo('')
                    }, 3000)
                }
            })
            .catch(error => {
                setPromo('promo code is not valid or expired')
                setTimeout(() => {
                    setPromo('')
                    setPromoCode('')
                }, 3000)
            });
        }
    };

    const handleCancelPromo = (e) => {
        setPromoValue("100")
        setPromo("")
        setPromoCode("")
    }

    return (
        <div>
            <Nav/>
            <form className='user-content' onSubmit={handleSubmit}>
                <div className='cost-header'>${promoValue}</div>
                <div className='questions-header'>Larus Branding</div>
                <div className='questions-body'>
                    <i>"Guiding brands and individuals to their authentic essence."</i>
                    <br></br><br></br>
                    Larus is the ultimate tool for creating an epic brand kit and upleveling your company's vibe. 
                    <br></br><br></br>What you get:
                    <br></br><br></br>→Basic brandkit components <i>(logo, palette, type, etc.)</i>
                    <br></br><br></br>→Awesome logo ideas <i>(6 concepts)</i>
                    <br></br><br></br>→Thoughtfully curated mission statements <i>(3 iterations)</i>
                    <br></br><br></br>→Inspiring environmental sustainability suggestions
                    <br></br><br></br>→Big ideas and insight moving forward
                    <br></br><br></br>Unlock your brand’s true potential with Larus, and stand out effortlessly.</div>
                    <div><a style={{color: 'inherit', textDecoration: 'none'}} href="/showcase/" target="_blank">Check out our <b><i>showcase</i></b> to see exactly what you'll be getting</a></div>
                    <br></br><br></br>
                <div className="kitchen-img">
                    <img src={require('./larus_cover.png')} />
                </div>
                <div className='text-area-header'>Company Name</div>
                <input
                    className='text-box'
                    placeholder='Enter your company name'
                    onChange={handleChange}
                    name='companyName'
                    value={data.companyName}
                    required
                />
                <div className='text-area-header'>Email</div>
                <input
                    className='text-box'
                    type='email'
                    placeholder="What's your email?"
                    style={{marginBottom:'10px'}}
                    onChange={handleChange}
                    name='email'
                    value={data.email}
                    required
                />
                <label>
                    <input
                    type="checkbox"
                    checked={data.subscribed}
                    onChange={checkboxChange}
                    style={{marginBottom:'30px'}}
                    />
                    &nbsp;Subscribe to the mailing list
                </label>
                <div className='text-area-header' style={{marginTop:'10px'}}>What does your company do? <i style={{fontSize:16}}>({data.about.length + '/250 characters'})</i></div>
                <textarea
                    className='text-area'
                    maxLength={250}
                    type="text"
                    minLength="25"
                    onChange={handleChange}
                    name='about'
                    value={data.about}
                    placeholder="Describe the main function of your company"
                    required
                />

                <div className='text-area-header' style={{marginTop:'10px'}}>What does your company stand for? Why did you start your company? <i style={{fontSize:16}}>({data.why.length + '/250 characters'})</i></div>
                <textarea
                    className='text-area'
                    maxLength={250}
                    onChange={handleChange}
                    name='why'
                    value={data.why}
                    placeholder="Describe the 'heart' of your company"
                />
                <div className='text-area-header' style={{marginTop:'10px'}}><span style={{fontWeight:'lighter', fontStyle:'italic'}}>Optional:</span> What vibe are you looking for? <i style={{fontSize:16}}>({data.vibe.length + '/250 characters'})</i></div>
                <textarea
                    className='text-area'
                    maxLength={250}
                    onChange={handleChange}
                    name='vibe'
                    value={data.vibe}
                    placeholder="Share words or ideas that describe the desired feel of your company"
                />
                <div className='text-area-header'><span style={{fontWeight:'lighter'}}>Promo Code</span></div>
                <div className='promo'>
                    <input
                        className='text-box-promo'
                        placeholder='Enter the promo code here'
                        onChange={handlePromoChange}
                        name='promo'
                        value={promoCode}
                    />
                    <button className='button-promo' type="button" onClick={checkPromo}>apply</button>
                </div>
                {promo && promo !== "promo code is not valid or expired" && (
                    <div className='promo-code'>
                        <FontAwesomeIcon icon={faGift} />
                        <span style={{ marginLeft: '5px' }}>{promo}</span> 
                        <span className='cancel-promo' onClick={handleCancelPromo}>x</span>
                    </div>
                )}
                {promo == "promo code is not valid or expired" && (
                    <div style={{ marginBottom: '20px', fontWeight: 'bold', color: '#FF00009F' }}>{promo}</div>
                )}
                {promo == "" && (
                    <div style={{ marginBottom: '20px' }}></div>
                )}
                <center>
                <div className='terms'>By pressing 'Continue To Payment' you agree to our <a style={{color: 'inherit', textDecoration: 'none', fontWeight: 'bold'}} href="https://drive.google.com/file/d/1L1rJwV20EHFnYRdCGtqAHRh4NeyTnM48/view" target="_blank">Terms</a> and <a style={{color: 'inherit', textDecoration: 'none', fontWeight: 'bold'}} href="https://drive.google.com/file/d/1nJPAtXH1zNQ2czghgYtuSPHHrEMUkXT-/view" target="_blank">Privacy Policy</a>.</div>
                <button className="continue-button" type="submit">Continue To Payment
                </button>
                </center>
            </form>
            <Footer/>
        </div>
    )
}
import React from 'react';
import { Nav } from './Nav';
import { Footer } from './Footer';
import './Showcase.css';

import { useNavigate } from 'react-router-dom';

import Logo from './larus.png';

const kitDescriptions = [
    "Larus starts by saying hello 👋",
    "Then we set the tone by diving into ideal typography ✍️",
    "Next, we explore the colors that most align with your brand 🎨",
    "Now we're ready to play around with some logo concepts ❤️",
    "And just in case you aren't satisfied, we've got some more 😁",
    "Now onto your core: we try on some mission statements to see how they fit 💪",
    "We want to leave you with some inspiration—a big idea to propel your brand to the next level 💡",
    "And for Mother Earth, we’ve added a little something 🌏",
    "Now it's time to say goodbye, but you can come back anytime you like! 👋"
];

export const Showcase = () => {
    let navigate = useNavigate();

    const brainstorm = () => {
        navigate('/brainstorming/');
    };
  return (
    <div>
      <Nav />
      <div className='showcase-intro'>
        <img style={{height: "150px"}} src={Logo}></img>
        <h1>Larus In Action</h1>
        <p>
          Discover the essence of brands that have trusted Larus to bring their visions to life. 
          Larus works with your brand to tell a story of creativity, passion, and purpose.
        </p>
      </div>
      
      <div className='brand-questions'>
        <h1>Mushu</h1>
        <label>What does your company do?</label>
        <textarea className='text-area-showcase' disabled>
          Hand planed and custom furniture built using wood species Indigenous to the Hudson Valley.
        </textarea>

        <label>What does your company stand for? Why did you start your company?</label>
        <textarea className='text-area-showcase' disabled>
          Sustainability and craftsmanship. I started Mushu to show that you don’t need to source wood from the Amazon or clear cut forests multiple time zones away. If you invest in your area, it’s all here. Change the mindset.
        </textarea>

        <label>What vibe are you looking for?</label>
        <textarea className='text-area-showcase' disabled>
          Simplicity, elegance, beauty, and function. White and black color scheme. Maybe some earth tones. Emphasis on the wood speaking for itself 
        </textarea>
      </div>
      
      <div className='brand-kit-showcase'>
        {[...Array(9)].map((_, index) => (
          <div key={index} className='brand-kit-item'>
            <p>{kitDescriptions[index]}</p>
            {/* <img src={`path-to-image-${index + 1}.jpg`} alt={`Brand Kit ${index + 1}`} /> */}
            {/* <img src={`https://images.unsplash.com/photo-1518770352423-dce09a3d3307`} alt={`Brand Kit ${index + 1}`} /> */}
            <img src={require(`./mushu/mushu_${index + 1}.png`)} alt={`Brand Kit ${index + 1}`} />
            {/* <iframe src={require("./mushu/one.pdf")} width="100%" height="auto" style={{ border: 'none' }}></iframe> */}
          </div>
        ))}
      </div>

      <div className='final-call-to-action'>
        <h2>We hope we've inspired you!</h2>
        <p>
          Seeing is believing, but creating your own brand vision with Larus is something else entirely.
          Why not give it a try and see the magic happen?
        </p>
        <button className='cta-button' onClick={brainstorm}>Create Your Brand Vision</button>
      </div>

      <Footer />
    </div>
  );
};

import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserInput } from './UserInput';
import { UserInputDemo } from './UserInputDemo';
import { UserInputPersonal } from './UserInputPersonal';
import { Payment } from './Payment';
import { Brand } from './Brand';
import Landing from './Landing';
import Preview from './Preview';

import { Showcase } from './Showcase';

function App() {
  const [currentPage, setCurrentPage] = useState('UserInput');

  const navigateToPayment = () => {
    setCurrentPage('Payment');
  };

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 'UserInput':
        return <UserInput onNavigate={navigateToPayment} />;
      case 'Payment':
        return <Payment />;
      default:
        return <UserInput onNavigate={navigateToPayment} />;
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/brainstorming" element={<UserInput/>} />
        <Route path="/demo" element={<UserInputDemo/>} />
        <Route path="/aboutyou" element={<UserInputPersonal/>} />
        <Route path="/payment" element={<Payment/>} />
        <Route path="/brand/:id" element={<Brand />} />
        <Route path="/showcase" element={<Showcase />} />
        <Route path="/preview" element={<Preview />} />
      </Routes>
    </Router>
  );
}

export default App;


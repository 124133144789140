import React, { useState } from 'react';
import './Footer.css'

import Instagram from './instagram.svg'
import Send from './send.svg'

export const Footer = () => {

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [email, setEmail] = useState('');
    const [subscribeHeader, setSubscribeHeader] = useState('Join the mailing list');

    const [sending, setSending] = useState(false);

    const emailRegex = new RegExp(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    );

    const textBoxStyle = (isValid) => ({
        border: `1px solid ${isValid ? '#ccc' : '#ff6b6b'}`,
        outline: 'none',
        transition: 'all 0.3s ease',
    });

    const handleChange = (e) => {
        setEmail(e.target.value)
        setIsValidEmail(true)
    };

    const handleClick = async (e) => {
        if (!sending) {
            const isValid = emailRegex.test(email);
            setIsValidEmail(isValid);
            if (isValid) {
                try {
                    setSending(true)
                    const response = await fetch('https://script.google.com/macros/s/AKfycbwX5yOvtsOJIfy0JYgviVGxJUnTvtmt9MQaxtD3Fvd04ns-xvHinsJH0bhTfgNArfRuLg/exec', {
                        method: 'POST',
                        body: JSON.stringify({"email": email})
                    });
                    setSubscribeHeader('Thank you for subscribing!')
                    setEmail('')
                } catch (error) {
                    console.error('There was an error:', error);
                }
                setSending(false)
            }
        }
    }

    return (
        <div>
            <div className='footer'>
                <div className='subscribe'>
                <div className='subscribe-header'>{subscribeHeader}</div>
                <div className='input-block'>
                <input
                    className='email-input'
                    type="email"
                    style={textBoxStyle(isValidEmail)}
                    onChange={handleChange}
                    placeholder="email"
                    value={email}
                />
                <div className='send-button'>
                    {!sending &&
                        <div onClick={handleClick} src={Send} className='send-button-copy'>
                            join
                        </div>
                    }
                    {sending &&
                        <div className='send-button-loading'/>
                    }
                    </div>
                </div>
                </div>
                <a style={{color: 'inherit'}} href='https://www.instagram.com/larus.life/' target="_blank">
                    <img src={Instagram} className='instagram-logo'/>
                </a>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import global from './Global';
import {Nav} from './Nav';
import {Footer} from './Footer';
import { useParams } from 'react-router-dom';
import './Brand.css';

import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './Utils';

import LoadingAnimation from './LoadingAnimation';

import JSZip from "jszip";
import { saveAs } from 'file-saver';

export const Brand = () => {
  let navigate = useNavigate();

  const { id } = useParams();
  const [cover, setCover] = useState('cover_url', '')
  const [url, setUrl] = useLocalStorage('brand_url', '')
  const [logos, setLogos] = useState('logos', [])
  const [isLoading, setIsLoading] = useState(false);

  const [isDownloadingPdf, setPdfDownloading] = useState(false);
  const [isDownloadingPngs, setPngsDownloading] = useState(false);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');

  const [reviewed, setReviewed] = useState(false);

  const [data, setData] = useLocalStorage('data', {
    companyName: '',
    email: '',
    about: '',
    why: '',
    vibe: '',
    subscribed: true,
  });

  const [promo, setPromo] = useLocalStorage('promotion', '')
  const [promoCode, setPromoCode] = useLocalStorage('promotionCode', '')
  const [promoValue, setPromoValue] = useLocalStorage('promotionValue', '100')

  const downloadPdf = () => {
    if (!isDownloadingPdf) {
      setPdfDownloading(true)
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          saveAs(blob, 'Larus Brand Vision.pdf');
          setPdfDownloading(false)
          window.open('/preview', '_blank');          
        })
        .catch(error => {
          console.error('Error downloading the PDF:', error)
          setPdfDownloading(false)
        });
    }
  };

  async function downloadPngs() {
    if (!isDownloadingPngs) {
      setPngsDownloading(true)
      const zip = new JSZip();
      for (let i = 0; i < logos.length; i++) {
        const response = await fetch(logos[i]);
        console.log(response)
        const blob = await response.blob();
        zip.file("logo_" + i.toString() + ".png", blob);
      }

      const zipData = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(zipData);
      link.download = "Larus Logos.zip";
      link.click();
      setPngsDownloading(false)
    }
  };

  const submitReview = () => {
    if (rating > 0 || review != "") {
      fetch(global.endpoint + "api/review/" + id, {
        method: "put",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "rating": rating,
            "review": review
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setReviewed(true)
      })
      .catch(error => {
          console.error('Error fetching data: ', error);
      });
    }
  };

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  useEffect(() => {
    if (!isLoading) {
      setPromo('')
      setPromoCode('')
      setPromoValue('100')
      setData({
        companyName: '',
        email: '',
        about: '',
        why: '',
        vibe: '',
        subscribed: true,
      })
      setIsLoading(true)
      fetch(global.endpoint + "api/brand/" + id, {
        method: "get"
      })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false)
        if (data.url) {
          setCover(data.cover)
          setUrl(data.url)
          setLogos(data.logos)
        }

        if (data.reviewed) {
          setReviewed(data.reviewed)
        }
      })
      .catch(error => {
          console.error('Error fetching data: ', error);
          setIsLoading(false);
      });
    }
    return () => {

    };
}, []);

  if (isLoading || url == "") {
    return (
      <div>
        <Nav />

        <div style={{display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 76px)'}}>
          <div style={{flex: '1'}}>
          <center>
            <div style={{ margin: '50px' }} className="video-container">
              <div>Loading... This will take a few minutes... Don't refresh the page</div>
              <br></br>
              <div className="spinner"><div style={{fontSize: '50px', lineHeight: '100px', verticalAlign: 'middle'}}>🦆</div></div>
              <br></br>
              <p style={{margin: '25px', maxWidth: '500px'}}><i>“I think I will sit on it a little while longer," said the duck, "as I have sat so long already, a few days will be nothing." "Please yourself," said the old duck, and she went away.”</i>
              <br></br><br></br>― Hans Christian Andersen, The Ugly Duckling</p>
            </div>
          </center>
          </div>
          <div style={{width: "100%"}}>
            <Footer />
          </div>
        </div>
      </div>
    );
    
    
  }

  return (
    <div className="brand-kit-page">
      <Nav />
      <header className="kit-header">
        <h1>Welcome to Your Brand Kit</h1>
        <p>Your guide to maintaining a cohesive and powerful brand identity.</p>
        <br></br>
      </header>

      <section className="download-section">
        <h2>Your Brand Vision</h2>
        <p>Everything you need to know about your brand’s vision, mission, and identity in one place.</p>
        <div>
          <div className='kit-image'>
            <img style={{borderRadius: '15px', width: '100%', height: '100%'}} src={cover}></img>
          </div>
        </div>
        <button onClick={downloadPdf} className="download-button">
          Download
        </button>
      </section>

      <section className="download-section">
        <h2>Your Logos</h2>
        <p>Here are your logo concepts, perfectly crafted for inspiration and ready to spark your next branding adventure.</p>
        <div style={{marginBottom: '10px'}}>
          <div className='kit-logos'>
            <img className='kit-logo' src={logos[0]}></img>
            <img className='kit-logo' src={logos[1]}></img>
            <img className='kit-logo' src={logos[2]}></img>
          </div>
          <div className='kit-logos'>
            <img className='kit-logo' src={logos[3]}></img>
            <img className='kit-logo' src={logos[4]}></img>
            <img className='kit-logo' src={logos[5]}></img>
          </div>
        </div>
        <button onClick={downloadPngs} className="download-button">
          Download
        </button>
      </section>

    {!reviewed &&
      <section className="download-section">
        <h2>Review Larus</h2>
        <p>
          Were you happy with your Brand Vision?
          Let us know what you thought so we can improve.
        </p>

        {/* Star Rating */}
        <div className="star-rating">
          {[...Array(5)].map((star, index) => (
            <span
              key={index}
              className={index < rating ? "star selected" : "star"}
              onClick={() => handleStarClick(index)}
              style={{ cursor: 'pointer', margin: '2.5px', fontSize: '40px', color: index < rating ? '#FFD700' : '#ddd' }}
            >
              ★
            </span>
          ))}
        </div>

        <div>
        <textarea
          className="review-text-area"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Leave your review here..."
          style={{ width: '100%', padding: '10px', marginTop: '10px' }}
          rows="5"
        ></textarea>
         </div>

        <button onClick={submitReview} className="download-button">
          Submit Review
        </button>
      </section>
      }

      <header className="kit-footer">
        <h1>Continue the journey with us!</h1>
        <br></br>
        <p>Interested in a partnership or bulk orders? <br></br>Contact us at <i>life.larus@gmail.com</i></p>
        <p>Subscribe to our mailing list below or follow us on <a style={{color: 'inherit', textDecoration: 'none', fontWeight: 'bold'}} href="https://www.instagram.com/larus.life/" target="_blank">instagram</a> ❤️</p>
      </header>

      <Footer />
    </div>
  );
};
import React, { useState, useEffect } from 'react';
import './Preview.css';

import { Nav } from './Nav.js';
import { useLocalStorage } from './Utils';

const Preview = () => {
    const [url, setUrl] = useLocalStorage('brand_url', '');
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
      <div className="preview-container">
        <Nav /> 
        {!isMobile &&
          <object className='preview' data={url} type="application/pdf"/>
        }
        {isMobile &&
          <object className='preview' data={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(url)}`} type="application/pdf"/>
        }

      </div>
    );
}

export default Preview;
